








import Vue from 'vue';

export default Vue.extend({
    name: 'LayoutAuth',

    data() {
        return {
            drawer: true,
        };
    },

    methods: {
        drawerToggle() {
            this.drawer = !this.drawer;
        },
    },
});
